import { ConditionOperandType, PlaceholderFormat } from "@cxp-shared/contracts";
import { DataSources } from "@cxp-shared/personalization-core";
import { getCdsClientUrl } from "@cxp-shared/services/CDS";
export var extractIconBasedOnType = function (type, isRelatedEntity) {
    if (isRelatedEntity === void 0) { isRelatedEntity = false; }
    if (type === "oneToManyRelationship" || type === "manyToManyRelationship") {
        return "GroupedList";
    }
    if (isRelatedEntity) {
        return "FlowChart";
    }
    if (type == null) {
        return "TextField";
    }
    if (type === "integer" || type === "bigInt" || type === "money" || type === "decimal" || type === "double") {
        return "NumberField";
    }
    if (type === "boolean") {
        return "ToggleRight";
    }
    if (type === "picklist") {
        return "BulletedList";
    }
    if (type === "multiSelectPicklist") {
        return "BulletedList";
    }
    if (type === "unspecified") {
        return "LookupEntities";
    }
    if (type === "lookup") {
        return "LookupEntities";
    }
    if (type === "dateTime") {
        return "DateTime2";
    }
    return "TextField";
};
export var extractIconBasedOnPlaceholderType = function (type, format, operandType) {
    if (type == null) {
        return "TextField";
    }
    if (type.some(function (k) { return k === "number" || k === "integer"; })) {
        return "NumberField";
    }
    if (type.some(function (k) { return k === "boolean"; })) {
        return "ToggleRight";
    }
    if (type.some(function (k) { return k === "array"; })) {
        return "BulletedList";
    }
    if (type.some(function (k) { return k === "object"; })) {
        return "LookupEntities";
    }
    if ((type.some(function (k) { return k === "string"; }) &&
        (format === PlaceholderFormat.DateTime || format === PlaceholderFormat.Time || format === PlaceholderFormat.Date)) ||
        operandType === ConditionOperandType.datetime) {
        return "DateTime2";
    }
    return "TextField";
};
export var extractIconBasedOnEntityType = function (entityType) {
    if (entityType == null) {
        return "Contact";
    }
    switch (entityType) {
        case "account":
            return "DocumentSet";
        case "contact":
            return "Contact";
        case "msdynci_customerprofile":
            return "ProfileSearch";
        case "lead":
            return "CRMLead";
        case "salesorder":
            return "KnowledgeArticle";
        case "Default": // legal
            return "CalligraphyPen";
        default:
            return "SetAction";
    }
};
export var extractBindingIconBasedOnEntity = function (logicalName, dataSource, metadataIcon, darkMode) {
    switch (dataSource) {
        case DataSources.CdsDataSource:
        case DataSources.DataverseDataSource:
        case DataSources.DataverseSpecificRecordsSource:
        case DataSources.BrandDataSource:
        case DataSources.SalesDataSource:
        case DataSources.JourneyDataSource:
        case DataSources.EventRegistrationFormDataSource:
            return resolveDataverseEntityIcon(logicalName, metadataIcon, darkMode);
        case DataSources.LegalDataSource:
            return legalIcon();
        default:
            return { iconType: "FontIcon", value: "SetAction" };
    }
};
export var errorIcon = function () {
    return { iconType: "FontIcon", value: "InfoSolid", style: { transform: "rotate(180deg)", color: "#A80000" } };
};
var legalIcon = function () {
    return { iconType: "FontIcon", value: "ComplianceAudit" };
};
export var extractIconColor = function (category, conditionListColor, defaultColor, darkMode) {
    if (category === "condition" || category === "list") {
        return conditionListColor ? conditionListColor : (darkMode ? "#fff" : "#605E5C");
    }
    return defaultColor ? defaultColor : (darkMode ? "#fff" : "#605E5C");
};
export var extractIcon = function (category, type, format, operandType) {
    if (category === "condition") {
        return "Variable";
    }
    if (category === "list") {
        return "BulletedList";
    }
    if (!type || type.length === 0) {
        return "TextField";
    }
    return extractIconBasedOnPlaceholderType(type, format, operandType);
};
var resolveDataverseEntityIcon = function (logicalName, metadataIcon, darkMode) {
    if (metadataIcon) {
        if (logicalName === "msdynmkt_brandprofile") {
            return { iconType: "FontIcon", value: "VerifiedBrand" };
        }
        return {
            iconType: "Url",
            value: "".concat(getCdsClientUrl(), "/WebResources/").concat(metadataIcon)
        };
    }
    switch (logicalName) {
        case "contact":
            return { iconType: "FontIcon", value: "Contact" };
        case "account":
            return { iconType: "FontIcon", value: "DocumentSet" };
        case "msdynci_customerprofile":
            return { iconType: "FontIcon", value: "ProfileSearch" };
        case "salesorder":
            return { iconType: "FontIcon", value: "KnowledgeArticle" };
        case "lead":
            return {
                iconType: "Base64",
                value: darkMode ? leadBase64IconDark : leadBase64Icon
            };
        case "systemuser": {
            return {
                iconType: "Base64",
                value: systemUserBase64Icon
            };
        }
        case "opportunity": {
            return {
                iconType: "Base64",
                value: opportunityBase64Icon
            };
        }
        default:
            return { iconType: "FontIcon", value: "Puzzle" };
    }
};
var opportunityBase64Icon = "data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6bm9uZTt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPk9wcG9ydHVuaXR5X0YwNUY8L3RpdGxlPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2Ii8+PHBhdGggZD0iTTE0LDBIMlYxNkgxM2ExLDEsMCwwLDAsMS0xVjcuMjVhMS4yOSwxLjI5LDAsMCwxLC4zNi0uOUEyLjI5LDIuMjksMCwwLDAsMTUsNC43NVYxQTEsMSwwLDAsMCwxNCwwWk0xMywxNUgzVjFoOVY0Ljc1YTIuMjksMi4yOSwwLDAsMCwuNjQsMS42LDEuMjksMS4yOSwwLDAsMSwuMzYuOVYxNVpNMTQsNC43NWExLjI5LDEuMjksMCwwLDEtLjM2LjksMi4yNCwyLjI0LDAsMCwwLS4xNC4xOCwyLjI0LDIuMjQsMCwwLDAtLjE0LS4xOCwxLjI5LDEuMjksMCwwLDEtLjM2LS45VjFoMVoiLz48cmVjdCB4PSI4IiB5PSI1IiB3aWR0aD0iMSIgaGVpZ2h0PSI0Ii8+PHJlY3QgeD0iOCIgeT0iMTAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiLz48L3N2Zz4=";
var systemUserBase64Icon = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMDQ4IDIwNDgiPg0KICA8cGF0aCBkPSJNMTI4IDIwNDhxMC0xNDMgNDEtMjc1dDExOS0yNDMgMTg2LTE5NSAyNDYtMTMycS03OC00Mi0xNDAtMTAyVDQ3NSA5Njh0LTY3LTE1Ni0yNC0xNzJxMC04OCAyMy0xNzB0NjQtMTUzIDEwMC0xMjlUNzAxIDg4dDE1My02NSAxNzAtMjNxODggMCAxNzAgMjN0MTUzIDY0IDEyOSAxMDAgMTAwIDEzMCA2NSAxNTMgMjMgMTcwcTAgODgtMjMgMTcxdC02NyAxNTctMTA2IDEzMy0xNDAgMTAycTEzNSA0OCAyNDQgMTMydDE4NyAxOTUgMTE5IDI0MyA0MiAyNzVIMTI4eiIgLz4NCjwvc3ZnPg==";
var leadBase64Icon = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMDQ4IDIwNDgiPg0KICA8cGF0aCBkPSJNMTE5NiAxNDAxcTM1IDAgNjggMTN0NTggMzlsMTYyIDE2MnEyNSAyNSAzOCA1OHQxNCA2OHEwIDM1LTEzIDY4dC0zOSA1OHEtNDQgNDQtNzkgNzh0LTc0IDU3LTg1IDM0LTExMiAxMnEtOTIgMC0xODktMjh0LTE5NS04MC0xOTAtMTIxLTE3Ny0xNTQtMTUzLTE3Ny0xMjItMTkwLTc5LTE5NVQwIDkxM3EwLTY1IDEyLTExMXQzNS04NSA1Ni03MyA3OC04MHEyNS0yNSA1OC0zOHQ2OC0xNHEzNSAwIDY4IDEzdDU4IDM5bDE2MiAxNjJxMjUgMjUgMzggNTh0MTQgNjhxMCAzNC0xMSA2MXQtMjcgNDktMzYgMzktMzUgMzMtMjggMzAtMTEgMzFxMCAyMCAxNSAzNWw0MDQgNDA0cTE1IDE1IDM1IDE1IDE2IDAgMzAtMTF0MzAtMjcgMzMtMzYgMzktMzUgNDktMjggNjItMTF6bS02MCA1MTlxNTAgMCA4My05dDYxLTI4IDUzLTQ1IDYwLTYxcTE1LTE1IDE1LTM2IDAtMjAtMTUtMzVsLTE2Mi0xNjJxLTE0LTE0LTM1LTE0LTI0IDAtNDYgMjN0LTQ4IDUxLTYyIDUxLTg3IDIzcS0zNSAwLTY4LTE0dC01OC0zOWwtNDA0LTQwNHEtMjUtMjUtMzktNTh0LTE0LTY4cTAtMzQgMTEtNjF0MjctNDkgMzYtMzkgMzYtMzMgMjgtMzAgMTEtMzFxMC0yMC0xNS0zNUwzNDIgNjU1cS0xNS0xNS0zNS0xNS0yMSAwLTM2IDE1bC02MCA2MHEtMjYgMjYtNDUgNTN0LTI4IDYwLTEwIDg0cTAgNzkgMjYgMTY0dDcyIDE3MSAxMDkgMTY5IDEzOCAxNTggMTU4IDEzOCAxNjkgMTEwIDE3MSA3MiAxNjUgMjZ6bTY3Mi0xMTIwbDYwIDMwMC0zMDAtNjAtMTYwIDI0MC0xNjAtMjQwLTMwMCA2MCA2MC0zMDAtMjQwLTE2MCAyNDAtMTYwLTYwLTMwMCAzMDAgNjBMMTQwOCAwbDE2MCAyNDAgMzAwLTYwLTYwIDMwMCAyNDAgMTYwLTI0MCAxNjB6bS0xMDMgMTM3cS0xMS00OS0yMC05N3QtMTktOTlxMzgtMjYgNzUtNTF0NzYtNTBxLTM4LTI1LTc1LTUwdC03Ni01MXE5LTQ5IDE5LTk4dDIwLTk4cS00OSAxMS05NyAyMHQtOTkgMTlxLTI2LTM4LTUxLTc1dC01MC03NnEtMjUgMzgtNTAgNzV0LTUxIDc2cS01MC05LTk4LTE4dC05OC0yMXExMSA0OSAyMCA5N3QxOSA5OXEtMzggMjYtNzUgNTF0LTc2IDUwcTM4IDI1IDc1IDUwdDc2IDUxcS05IDQ5LTE5IDk4dC0yMCA5OHE0OS0xMSA5Ny0yMHQ5OS0xOXEyNiAzOCA1MSA3NXQ1MCA3NnEyNS0zOCA1MC03NXQ1MS03NnE1MCA5IDk4IDE4dDk4IDIxeiIgLz4NCjwvc3ZnPg==";
var leadBase64IconDark = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMDQ4IDIwNDgiPg0KICA8cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTExOTYgMTQwMXEzNSAwIDY4IDEzdDU4IDM5bDE2MiAxNjJxMjUgMjUgMzggNTh0MTQgNjhxMCAzNS0xMyA2OHQtMzkgNThxLTQ0IDQ0LTc5IDc4dC03NCA1Ny04NSAzNC0xMTIgMTJxLTkyIDAtMTg5LTI4dC0xOTUtODAtMTkwLTEyMS0xNzctMTU0LTE1My0xNzctMTIyLTE5MC03OS0xOTVUMCA5MTNxMC02NSAxMi0xMTF0MzUtODUgNTYtNzMgNzgtODBxMjUtMjUgNTgtMzh0NjgtMTRxMzUgMCA2OCAxM3Q1OCAzOWwxNjIgMTYycTI1IDI1IDM4IDU4dDE0IDY4cTAgMzQtMTEgNjF0LTI3IDQ5LTM2IDM5LTM1IDMzLTI4IDMwLTExIDMxcTAgMjAgMTUgMzVsNDA0IDQwNHExNSAxNSAzNSAxNSAxNiAwIDMwLTExdDMwLTI3IDMzLTM2IDM5LTM1IDQ5LTI4IDYyLTExem0tNjAgNTE5cTUwIDAgODMtOXQ2MS0yOCA1My00NSA2MC02MXExNS0xNSAxNS0zNiAwLTIwLTE1LTM1bC0xNjItMTYycS0xNC0xNC0zNS0xNC0yNCAwLTQ2IDIzdC00OCA1MS02MiA1MS04NyAyM3EtMzUgMC02OC0xNHQtNTgtMzlsLTQwNC00MDRxLTI1LTI1LTM5LTU4dC0xNC02OHEwLTM0IDExLTYxdDI3LTQ5IDM2LTM5IDM2LTMzIDI4LTMwIDExLTMxcTAtMjAtMTUtMzVMMzQyIDY1NXEtMTUtMTUtMzUtMTUtMjEgMC0zNiAxNWwtNjAgNjBxLTI2IDI2LTQ1IDUzdC0yOCA2MC0xMCA4NHEwIDc5IDI2IDE2NHQ3MiAxNzEgMTA5IDE2OSAxMzggMTU4IDE1OCAxMzggMTY5IDExMCAxNzEgNzIgMTY1IDI2em02NzItMTEyMGw2MCAzMDAtMzAwLTYwLTE2MCAyNDAtMTYwLTI0MC0zMDAgNjAgNjAtMzAwLTI0MC0xNjAgMjQwLTE2MC02MC0zMDAgMzAwIDYwTDE0MDggMGwxNjAgMjQwIDMwMC02MC02MCAzMDAgMjQwIDE2MC0yNDAgMTYwem0tMTAzIDEzN3EtMTEtNDktMjAtOTd0LTE5LTk5cTM4LTI2IDc1LTUxdDc2LTUwcS0zOC0yNS03NS01MHQtNzYtNTFxOS00OSAxOS05OHQyMC05OHEtNDkgMTEtOTcgMjB0LTk5IDE5cS0yNi0zOC01MS03NXQtNTAtNzZxLTI1IDM4LTUwIDc1dC01MSA3NnEtNTAtOS05OC0xOHQtOTgtMjFxMTEgNDkgMjAgOTd0MTkgOTlxLTM4IDI2LTc1IDUxdC03NiA1MHEzOCAyNSA3NSA1MHQ3NiA1MXEtOSA0OS0xOSA5OHQtMjAgOThxNDktMTEgOTctMjB0OTktMTlxMjYgMzggNTEgNzV0NTAgNzZxMjUtMzggNTAtNzV0NTEtNzZxNTAgOSA5OCAxOHQ5OCAyMXoiIC8+DQo8L3N2Zz4=";
