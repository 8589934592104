var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { normalizeGuid } from "../../../Shared/PersonalizationCore";
var XrmUtils = /** @class */ (function () {
    function XrmUtils() {
    }
    XrmUtils.showProgress = function (show, message) {
        var util = window.Xrm.Utility;
        show ? util.showProgressIndicator(message || "") : util.closeProgressIndicator();
    };
    XrmUtils.navigateBackWithoutConfirmation = function () {
        var xrm = window.Xrm;
        // removes IsDirty property from all the fields
        var attributes = xrm.Page.data.entity.attributes.get();
        for (var i in attributes) {
            attributes[i].setSubmitMode("never");
        }
        xrm.Navigation.navigateBack();
    };
    XrmUtils.openEntityForm = function (entityName, formId, openInDialog) {
        if (openInDialog === void 0) { openInDialog = false; }
        var xrm = window.Xrm;
        var navigationOptions = openInDialog ? {
            target: 2,
            width: { value: 90, unit: "%" },
            heigth: { value: 80, unit: "%" },
            position: 1
        } :
            {
                target: 1
            };
        xrm.Navigation.navigateTo({
            pageType: "entityrecord",
            entityName: entityName,
            entityId: this.getFormId(),
            formId: formId,
        }, navigationOptions);
    };
    XrmUtils.getFormId = function () {
        var _a, _b, _c, _d;
        return normalizeGuid((_d = (_c = (_b = (_a = window.Xrm) === null || _a === void 0 ? void 0 : _a.Page) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.entity) === null || _d === void 0 ? void 0 : _d.getId());
    };
    XrmUtils.getOrgId = function () {
        var _a, _b, _c, _d;
        return (_d = (_c = (_b = (_a = window.Xrm) === null || _a === void 0 ? void 0 : _a.Utility) === null || _b === void 0 ? void 0 : _b.getGlobalContext()) === null || _c === void 0 ? void 0 : _c.organizationSettings) === null || _d === void 0 ? void 0 : _d.organizationId;
    };
    XrmUtils.getIsTrial = function () {
        var _a, _b, _c;
        var xrm = window.Xrm;
        return (_c = (_b = (_a = xrm === null || xrm === void 0 ? void 0 : xrm.Utility) === null || _a === void 0 ? void 0 : _a.getGlobalContext()) === null || _b === void 0 ? void 0 : _b.organizationSettings) === null || _c === void 0 ? void 0 : _c.isTrialOrganization;
    };
    XrmUtils.retrieveRecord = function (entityLogicalName, entityId, options) {
        return __awaiter(this, void 0, void 0, function () {
            var xrm;
            return __generator(this, function (_a) {
                xrm = window.Xrm;
                return [2 /*return*/, xrm === null || xrm === void 0 ? void 0 : xrm.WebApi.retrieveRecord(entityLogicalName, entityId, options)];
            });
        });
    };
    XrmUtils.getFeatureControlSetting = function (namespace, key) {
        var _a, _b, _c;
        return ((_c = (_b = (_a = window.Xrm) === null || _a === void 0 ? void 0 : _a.Utility) === null || _b === void 0 ? void 0 : _b.getGlobalContext()) === null || _c === void 0 ? void 0 : _c.getFeatureControlSetting(namespace, key)) || localStorage.getItem("".concat(namespace, ":").concat(key)) === "true";
    };
    XrmUtils.getAttributeEntityId = function (formContext, name) {
        var _a;
        var value = (_a = formContext.getAttribute(name)) === null || _a === void 0 ? void 0 : _a.getValue();
        if (value === null || value === void 0 ? void 0 : value[0]) {
            return normalizeGuid(value[0].id || value[0]["Id"].toString());
        }
        else {
            return null;
        }
    };
    return XrmUtils;
}());
export { XrmUtils };
