var HtmlAttributes = /** @class */ (function () {
    function HtmlAttributes() {
    }
    // Used by content editor to load Tile configuration by id
    HtmlAttributes.blockType = "data-editorblocktype";
    HtmlAttributes.dataLayout = "data-layout";
    HtmlAttributes.dataSectionAttrName = "data-section";
    HtmlAttributes.classAttrName = "class";
    // FormFields html attributes
    HtmlAttributes.targetAudience = "data-targetaudience";
    HtmlAttributes.targetProperty = "data-targetproperty";
    HtmlAttributes.hide = "data-hide";
    HtmlAttributes.dataRequired = "data-required";
    HtmlAttributes.dataCountryCode = "data-countrycode";
    HtmlAttributes.prefill = "data-prefill";
    HtmlAttributes.title = "title";
    HtmlAttributes.value = "value";
    // FormFields html attribute values
    HtmlAttributes.requiredValue = "required";
    HtmlAttributes.pattern = "pattern";
    HtmlAttributes.maxLength = "maxlength";
    HtmlAttributes.type = "type";
    // TwoOption field html attributes
    HtmlAttributes.optionsValues = "data-options";
    // Lookup field html attributes
    HtmlAttributes.publicVisibility = "data-publicvisibility";
    HtmlAttributes.lookupTargets = "data-lookuptargets";
    // Label elements on canvas
    HtmlAttributes.nonfocusableFor = "data-for";
    HtmlAttributes.forAttribute = "for";
    HtmlAttributes.editorWrapperSelector = "[contenteditable]";
    // Form/placeholder attributes
    HtmlAttributes.successMessage = "data-successmessage";
    HtmlAttributes.errorMessage = "data-errormessage"; // also used for validation
    HtmlAttributes.redirectUrl = "data-redirecturl";
    HtmlAttributes.unboundForm = "data-unboundform";
    HtmlAttributes.preventSubmissionUi = "data-preventsubmissionui";
    HtmlAttributes.feedbackPosition = "data-feedback-position";
    // Captcha attributes
    HtmlAttributes.captchaLanguage = "data-captchalanguage";
    // Form validation
    HtmlAttributes.validateSubmission = "data-validate-submission";
    // Sessions attributes
    HtmlAttributes.dataAvailableSeats = "data-availableseats";
    return HtmlAttributes;
}());
export { HtmlAttributes };
